.vultr {
  height: 200px;
  width: 100%;
  position: relative;
  transition: all .4s;
  border-width: 0px;
  background: linear-gradient(45deg, #433aa3 1%, #5856cd 64%, #6769eb 97%);
  color: var(--bg-primary);
  box-shadow: 0px 5px 30px -5px rgba(37, 45, 51, 50%);
  font-size: 2rem;
  font-weight: 500;
  border: none;
  border-radius: 0;

  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: stretch;
  flex-wrap: wrap;
  align-items: stretch;
}