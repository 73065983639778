.list-group {
  margin: 4rem auto;
}

.list-group .list-group-item:first-child,
.list-group .list-group-item:last-child {
  border-radius: .5rem;
}

.list-group a, .list-group a.active {
  margin: .5rem;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;

  /* background-color: rgba(31,41,55,var(--tw-bg-opacity));
  color: rgba(243,244,246,var(--tw-text-opacity)); */

  background: linear-gradient(45deg, #433aa3 1%, #5856cd 64%, #6769eb 97%);
  color: var(--bg-primary);
  box-shadow: 0px 5px 30px -5px rgba(37, 45, 51, 50%);

  border-radius: .5rem;
  color: white;
}

.list-group a:hover {
  color: rgba(31,41,55,1);
  background-color: rgba(0, 188, 210, .5);
  font-size: 1.02rem;
}